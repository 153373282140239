import React, { CSSProperties } from 'react'
import { navigate } from 'gatsby';

export default () => (
	<svg
		height="100vh"
		width="100vw"
		viewBox="0 0 2279 1495"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		preserveAspectRatio="xMidYMin slice"
	>

		<defs>
			<rect id="path-1" x="0" y="0" width="2279" height="1496"></rect>
		</defs>
		<g id="D-404" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="kjallari_404_mynd" transform="translate(0.000000, -1.000000)">
				<mask id="mask-2" fill="white">
					<use xlinkHref="#path-1"></use>
				</mask>
				<use id="Mask" xlinkHref="#path-1"></use>
			</g>
			<g id="Talbola" transform="translate(874.000000, 378.000000)">
				<g id="talbola">
					<path d="M231.137931,313.869647 C253.221846,313.869647 259.024134,310.815521 279.413793,306.971231 C283.866586,306.131697 353.697302,368.010607 358.034483,366.98745 C361.051725,366.275671 319.216243,293.975085 322.172414,293.174399 C397.19569,272.854182 447,233.766055 447,155.895923 C447,50.3501591 373.206897,-0.00827714819 231.137931,2.06124763 C89.0689655,4.1307724 7,71.8619354 7,157.965447 C7,244.068959 115.729463,313.869647 231.137931,313.869647 Z" id="Oval" fillOpacity="0.7" fill="#FFFFFF"></path>
					<path d="M355.737428,368.726474 C355.657901,360.511685 320.780162,313.643728 314.121634,294.589895 C313.61524,293.140813 409.89815,280.143288 431.254259,214.71136 C452.610369,149.279431 437.690071,82.29478 398.007856,55.957319 C327.288726,9.02036462 282.324144,-0.700326997 240.799861,0.0379853332 C37.0052136,3.66150616 -29.8765807,126.189787 17.5740084,214.71136 C27.7154554,233.630763 54.3640982,258.348869 89.2495622,277.09757 C204.766765,339.180663 258.44457,304.252544 268.770531,307.981824 C326.776595,351.384175 355.76556,371.632391 355.737428,368.726474 Z" id="Path-64-Copy-3" stroke="#FFFFFF" strokeWidth="5"></path>
					<path d="M365.809579,368.7358 C365.730205,360.521182 336.578204,310.852605 329.932588,291.799166 C329.427176,290.350115 410.533831,283.7779 431.848528,218.347329 C453.163225,152.916759 443.225191,88.9773189 407.998038,55.9731384 C348.179638,-0.0704827371 273.726657,5.78341105 232.282897,6.52170805 C28.8834367,10.1451537 -32.1803325,126.510414 15.178243,215.030149 C25.3000241,233.94916 54.7827646,264.047081 89.6005806,282.795393 C204.893779,344.877197 261.321252,301.812565 271.62719,305.541768 C334.443527,350.57698 365.837656,371.641658 365.809579,368.7358 Z" id="Path-64-Copy-4" stroke="#FFFFFF" strokeWidth="5"></path>
					<path d="M363.949189,367.717792 C363.869747,359.484098 324.115273,311.341073 317.463949,292.243388 C316.958103,290.790972 414.255834,281.305659 435.588837,215.723146 C456.921841,150.140633 440.007987,84.934741 404.750578,51.8539184 C344.880802,-4.31984678 271.172952,0.0807422033 229.684152,0.0807422033 C94.4702451,0.0807422033 -26.940828,114.173303 20.4584226,202.898598 C30.5888972,221.861542 63.418003,258.679122 98.2657232,277.470971 C213.657944,339.696941 270.133882,305.082004 280.448671,308.819867 C336.143785,350.997755 363.97729,370.630397 363.949189,367.717792 Z" id="Path-64-Copy-5" stroke="#FFFFFF" strokeWidth="5"></path>
				</g>
				<text cursor="pointer" onClick={() => { navigate('/') }} id="goBack" transform="translate(232.500000, 186.428817) rotate(-2.000000) translate(-232.500000, -186.428817)" fontFamily="AdelleSans-Bold, Adelle Sans" fontSize="36" fontWeight="bold" line-spacing="45" fill="#98139C">
					<tspan x="61.842" y="107.724817">Úps, við erum komin </tspan>
					<tspan x="75.9" y="152.724817">niður í bílakjallara. </tspan>
					<tspan x="35.346" y="197.724817">Hér er ekkert að gerast.</tspan>
					<tspan x="118.2" y="249.928817" fontSize="22.5">Aftur á forsíðu</tspan>
				</text>
				<g id="OR" transform="translate(235.500000, 258.928817) rotate(174.000000) translate(-235.500000, -258.928817) translate(116.000000, 248.928817)" stroke="#00FFFF" strokeLinecap="round" strokeWidth="3.6">
					<path d="M120.519285,-109.760329 C119.907573,-30.0922241 119.295861,49.5758805 118.684149,129.243985" id="Path-26" transform="translate(119.601717, 9.741828) scale(-1, 1) rotate(-94.000000) translate(-119.601717, -9.741828) "></path>
					<path d="M117.141889,-112.961448 C118.84687,-33.5475553 120.551851,45.8663373 122.256833,125.28023" id="Path-26" transform="translate(119.699361, 6.159391) scale(-1, 1) rotate(-91.000000) translate(-119.699361, -6.159391) "></path>
				</g>
			</g>
			<g id="OR-" transform="translate(1707.000000, 280.000000)" stroke="#00FFFF" strokeLinecap="round" strokeWidth="5.5125">
				<path d="M22.6445312,0 C22.5481771,24.3333333 22.4518229,48.6666667 22.3554688,73" id="Path-26" transform="translate(22.500000, 36.500000) scale(-1, 1) translate(-22.500000, -36.500000) "></path>
				<path d="M0,53.3010491 C8.12744518,64.0659392 11.7118385,79.366582 24.3823356,85.5957194 C30.1678673,88.4400334 31.436072,75.5278929 34.4562454,70.2406124 C38.9839417,62.3141797 42.8187485,54.0802041 47,46" id="Path-44"></path>
			</g>
			<g id="OR-" transform="translate(1707.000000, 255.000000)" stroke="#00FFFF" strokeLinecap="round" strokeWidth="5.5125">
				<path d="M22.6445312,0 C22.5481771,24.3333333 22.4518229,48.6666667 22.3554688,73" id="Path-26" transform="translate(22.500000, 36.500000) scale(-1, 1) translate(-22.500000, -36.500000) "></path>
				<path d="M0,53.3010491 C8.12744518,64.0659392 11.7118385,79.366582 24.3823356,85.5957194 C30.1678673,88.4400334 31.436072,75.5278929 34.4562454,70.2406124 C38.9839417,62.3141797 42.8187485,54.0802041 47,46" id="Path-44"></path>
			</g>
			<text id="404" fontFamily="Adelle-Bold, Adelle" fontSize="80" fontWeight="bold" fill="#8C299B">
				<tspan x="1637" y="189">404</tspan>
			</text>
			<g id="kall" transform="translate(168.000000, 697.000000)" strokeLinecap="round" strokeWidth="4">
				<path d="M132,0 C115.542092,0 66.1362957,0 49.5673469,0 C36.0979592,0 17.1934463,32.1205593 0,67" id="Path-82" stroke="#00FFFF"></path>
				<path d="M149,186 C161.40856,191.980944 189.456677,204.035416 195.069831,215.491789 C200.986541,227.567715 197.042665,242.326846 197.406064,255.770885 C199.715518,341.209707 194.555658,266.670863 200,334" id="Path-84" stroke="#FFFFFF"></path>
			</g>
			<g id="hurd" transform="translate(1696.000000, 391.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeWidth="6.615">
				<path d="M0,0 C64.7554841,1.98687175 130.323478,9.21096632 195.129841,6.20746195 C204.523091,5.77212404 247.739774,-5.7279121 250.647201,6.68056681 C256.633195,32.2279342 259.21969,126.989281 258.985453,146.209792 C258.733913,166.850097 245.403281,713.1421 250.99531,731.660734 C251.512746,733.374281 251.574128,728.128762 251.863537,726.362775" id="Path-89"></path>
				<path d="M37,1 C101.505463,2.98687175 166.820299,10.2109663 231.376444,7.20746195 C240.733426,6.77212404 283.78325,-4.7279121 286.679451,7.68056681 C292.642334,33.2279342 295.218842,127.989281 294.985509,147.209792 C294.73494,167.850097 281.455778,714.1421 287.026216,732.660734 C287.541654,734.374281 287.602799,729.128762 287.891091,727.362775" id="Path-89-Copy"></path>
				<path d="M2.5,19 C2.5,102.496728 2.5,648.569589 2.5,728" id="Path-90"></path>
				<path d="M19.5,19 C19.5,102.496728 19.5,648.569589 19.5,728" id="Path-90-Copy"></path>
			</g>
		</g>
	</svg>
)